@import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700');
@import '../styles/colors';

$font-size-xxxs: 9px;
$font-size-xxs: .625rem;
$font-size-sm: .85rem;
$font-size-wxs: $font-size-sm;
$font-size-xs: $font-size-sm;
$font-size-ss: .75rem;
$font-size-s: .725rem;
$font-size-md: .9rem;
$font-size-mdlg: 1rem;
$font-size-lg: 1.1rem;
$font-size-xlg: 1.2rem;
$font-size-xxl: 1.7rem;
$font-size-xxxl: 2.1rem;

body, button {
  font-family: 'Hind', sans-serif;
}

a {
  color: $off-white;
}

$breakpoint-trades: 1229px;
$breakpoint-trades-off: 1229.98px;
$breakpoint-trades-stage: 1230px;
$breakpoint-trades-with-composer: 1542px;
$breakpoint-trades-with-composer-off: 1541.98px;