@import '../../styles/colors';
@import '../../styles/typography';

$breakpoint-phones-off: 575.98px;
$breakpoint-phones: 576px;

$breakpoint-tablets-off: 767.98px;
$breakpoint-tablets: 768px;

$breakpoint-laptops-off: 991.98px;
$breakpoint-laptops: 992px;

$breakpoint-desktops-off: 1199.98px;
$breakpoint-desktops: 1120px;

.px-sm-2 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.flex-column {
  flex-direction: column !important;
  margin-right: 1.87rem !important;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.ml-auto {
  margin-left: auto !important;
}

.align-self-sm-stretch {
   align-self: stretch !important;
}

.pt-sm-0 {
  padding-top: 0 !important;
}

.collapse:not(.show) {
  display: none;
}

.navbar-collapse:not(.collapse) {
  margin-top: 19px;
  width: auto;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  animation: titleLoad 2.2s cubic-bezier(0, 0.26, 0.47, 0.98);

  &-login-brand {
    height: 40px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  &-nav {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    position: static;
    float: none;
    left: 10%;
  }

  &-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }

  &-toggler:not(.collapsed) &-toggler-icon {
    background-color: transparent;
  }

  &-toggler:not(.collapsed) &-toggler-icon::before {
    transform: rotate(45deg);
  }

  &-toggler:not(.collapsed) &-toggler-icon::after {
    transform: rotate(-45deg);
  }

  &-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    position: relative;
    width: 40px;
    height: 40px;
    margin-top: 2px;
    margin-right: 10px;

    &-icon {
      display: inline-block;
      height: 2px;
      vertical-align: middle;
      content: "";
      background-color: $white;
      background-size: 100% 100%;
      position: absolute;
      width: 80%;
      transform: translate3d(-50%, -50%, 0);
      &:after,
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $white;
      }

      &:before {
        transform: translate3d(0, -8px, 0);
      }
      &:after {
        transform: translate3d(0, 8px, 0);
      }

      .navbar-toggler:not(.collapsed) & {
        background-color: transparent;
        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }

    }
  }

  &-toggler:hover, &-toggler:focus {
    text-decoration: none;
  }

  &-btn {
    color: $pearl;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  &-logo {
    .tuned-logo {
       width: 148.8px;
       height: 12.98px;
       vertical-align: middle;
       margin-bottom: 24px;
       margin-top: 3px;
    }
  }
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar .navbar-nav .nav-item:not(.nav-login) {
  color: $pearl;
}

.nav-login {
  color: $cantaloupe;
}

.navbar-btn {
  font-weight: 600 !important;
}

.navbar .navbar-nav .nav-item {
  font-size: $font-size-mdlg;
  display: block;
  text-decoration: none;
  font-weight: 500;
  margin-bottom: 1em;
}

.navbar-login-brand {
  .nav__brand {
    .tuned-logo {
      margin-left: 1px;
      height: 16px;
      width: 92px;
    }
  }

  .nav__brand--header {
    margin-left: 4.5px;
    margin-right: 5px;
    width: 115px;
  }

  .nav__version {
    background-color: $pine;
    margin-left: 6.2px;
    width: 33.8px;
    height: 8px;
    font-size: .5rem;
    padding: 1px 4px 4px 4px;
    letter-spacing: 1px;
  }
}

.navbar .navbar-nav .nav-item:hover {
  color: $cantaloupe;
}

@media only screen and (min-width: $breakpoint-tablets) {
    .navbar .navbar-nav .nav-item {
      font-size: $font-size-xs;
      margin-bottom: 0;
    }

    .navbar {
      height: 65px;
      background: transparent;
      position: sticky;
      top: 0;
      width: 100%;
    }

    .navbar-btn {
      background-color: $pine;
      transition: color, background-color;
      transition-duration: .125s;
    }

    .navbar-btn:hover {
      color: white;
    }

    .navbar-btn:hover {
      background-color: $cantaloupe;
      color: white;
    }

    .navbar .navbar-nav .nav-item.navbar-btn {
      color: $white;
    }
    .navbar .navbar-nav .nav-item.navbar-btn:hover {
      color: $white;
    }
}

@media (max-width: $breakpoint-tablets-off) {
  .nav-item {
    padding-left: .6rem !important;
  }

  .navbar-login-brand {
    height: 40px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .nav__brand {
      margin-left: 3px;

      .tuned-logo {
        margin-left: 2px;
        display: none;

        &--mobile {
          display: unset;
          width: unset;
          height: 20px;
        }
      }
    }
  }

  .navbar {
    background-color: $soot;
    background: $soot;
    margin-bottom: 50px;
    padding: 4px 0 5px 0;
  }
}

@media (min-width: $breakpoint-tablets) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 2rem !important;
  }

  .navbar-login-brand {
    .nav__brand {
      margin-left: 2.15rem!important;

      .tuned-logo {
        &--mobile {
          display: none;
        }
      }
    }
  }
}

@keyframes titleLoad {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}