@import '../../styles/colors';
@import '../../styles/typography';

.modal-overlay {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $transparent-black;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;


    .modal {

        &:focus-within, &:focus {
            outline: none;
        }

        padding: 2em;
        position: relative;
        background-color: $black;
        overflow-y: auto;
        z-index: 100;
        max-height: calc(100% - 80px);

        .button {
          margin: auto;

          &.button--primary {
            margin-bottom: 1em;
          }
        }

        .label {
            font-weight: 600;
            padding-bottom: 2em;
            display: block;
        }

        .form__field {
            width: 100%;
            margin-bottom: 20px;

            label {
                display: block;
                text-transform: none;
                font-size: $font-size-sm;
                color: $gray;
                position: relative;
                white-space: nowrap;
            }

            input {
                min-width: 300px;
                color: $off-white;
            }
        }

        &__close-btn {
            opacity: 0.7;
            &:hover {
                opacity: 1;
            }
            position: absolute;
            top: 20px;
            right: 20px;

            img {
                width: 30px;
                height: 30px;
            }
        }

    }

}
