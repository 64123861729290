$arrow-color: #A7A7A7;
$arrow-size: 12px;

.arrow {
  &--down {
    border-left: ($arrow-size - 4) solid transparent;
    border-right: ($arrow-size - 4) solid transparent;
    border-top: $arrow-size solid $arrow-color;
    height: 0; 
    width: 0;
  }
}