@import '../../styles/colors';
@import '../../styles/typography';

.admin-dashboard {
    &__navigation {
        display: flex;
        flex-wrap: wrap;
        background-color: $off-black;
    
        &--tab {
            padding: 6px 10px;
            height: 18px;
            font-size: $font-size-sm;
            font-weight: 500;
            border-right: 2px solid $black !important;
            border-bottom: 2px solid $black !important;
            border-top: 2px solid $black !important;
            color: $off-white;
    
            &:hover {
                color: $light-gray;
                cursor: pointer;
            }
    
            &.active {
                background-color: $black;
                color: $white;
                font-weight: 600;
            }

            &.create-button {
                margin-left: auto;
                border-left: 2px solid $black !important;
                border-right: 0 !important;
                padding-top: 7px;
                padding-bottom: 5px;
                position: relative;

                &:hover {
                    cursor: pointer;

                    img {
                        filter: grayscale(100%) brightness(232%);
                    }
                }

                .dropdown-menu {
                    position: absolute;
                    z-index: 9;
                    right: 2px;
                    margin-top: 5px;
                    padding: 5px 0;
                    width: 214px;
                    background-color: $off-black;

                    &--option {
                        padding: 2px 10px 0;
                        color: $off-white;

                        &:hover {
                            color: $light-gray;
                            background-color: $steel;
                        }
                    }
                }
            }
        }
    }
}