@import '../../styles/colors';
@import '../../styles/typography';

button {
  cursor: pointer;
}

.MuiTooltip {
  &-tooltip {
    background-color: rgba(97, 97, 97, 1) !important;
    font-family: 'Hind' !important;
    font-size: $font-size-xs !important;
  }
}

.strategy-index {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  position: relative;

  &.open-composer {
    @media (max-width: 1058.98px) {
      width: calc(100% - 310px);
      flex: none;

      .execution-name {
        display: none;
      }

      .main-nav {
        &.scripts {
          .nav__item {
            &.middle-left, &--minimize, &--create, &--dropdown {
              display: none;
            }
          }
        }
      }

      .nav__item {
        &--icon {
          .editor-button {
            border-right: 2px solid $black;
          }

          & + .nav__item--icon {
            border-left: 0;
          }
        }
      }

      .revisions-dropdown, .open-revisions {
        display: none;
      }

      .right-nav {
        .nav__link {
          padding-left: 7px;
          padding-right: 7px;
        }
      }

      .selected-script {
        max-width: unset !important;
        width: calc(100% - 273px) !important;
      }

      .MuiPaper-root {
        max-width: unset !important;
        width: calc(100% - 222px) !important;
      }
    }

    @media (max-width: 1022.98px) {
      .left-delete-icon {
        display: none;
      }
    }

    @media (max-width: 979.98px) {
      .main-nav {
        &:not(.scripts) {
          .nav__item {
            &--divider.middle-right {
              display: none;
            }
          }
        }
      }
    }

    @media (max-width: 767.98px) {
      .nav {
        &.main-nav {
          display: unset;

          .nav {
            &__item {
              height: 29px;

              &--right {
                margin-left: 0;
                border-right: 2px solid $black;
              }
            }
          }
        }
      }
    }
  }

  .main-panel {
    flex: 1;
    flex-direction: column;
    display: flex;

    &.top-panel {
      flex-direction: row;

      &.open-portfolio-and-composer {
        max-width: calc(100vw - 312px);

        .open-portfolio-chart {
          &.open-asset-explorer {
            max-width: calc(100% - 358px);
          }
        }
      }
    }

    @media (max-width: 997.98px) {
      width: 100vw;
    }

    .main-nav {
      margin: 0.75rem 0.75rem 0rem 0.75rem;
    }

    .mvt-filters {
      .filter-list {
        padding: 0 0 0 10px;
      }
    }
  }

  .filter-list-container {
    padding-left: 10px;

    &.filter-with-composer {
        @media (max-width: $breakpoint-trades-with-composer-off) {
            width: calc(100% - 110px);
            border-right: 0;
        }
    }

    @media (max-width: 767.97px) {
      max-width: 100%;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  .narrow-filter-list {
    @media (min-width: $breakpoint-trades-off) {
      width: calc(100% - 566px);
      border-right: 6px solid $off-black;
    }
  }

  .filter-list-loading {
    height: 39px;
  }

  &.collapsed {
    .strategy-index {
      &__panels {
        display: none;
      }
    }
  }

  .button.show-panels {
    position: fixed;
    bottom: 0;
    right: 7rem;
    width: 100px;
    border-color: $gray;
    background-color: $off-black;
    cursor: pointer;
    font-size: $font-size-sm;
    padding: 8px 0;
    border-width: 1px;
    border-bottom: 0;

    &:hover {
      background-color: $dark-gray;
    }

    &.open-composer {
      right: calc(310px + 2rem);
    }
}

  &__panels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    min-height: 20px;
  }

  &__chart {
    flex: 1;
    position: relative;

    .TVChartContainer {
      height: 100%;
      display: flex;
    }
  }

  &__top-left-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    &.open-asset-explorer {
      max-width: calc(100vw - 358px);
    }

    &.open-portfolio-chart:not(.open-asset-explorer) {
      max-width: calc(100% - 5px);
    }
  }

  .minimize {
    position: absolute;
    top: 0.75rem;
    right: 1.5rem;
    z-index: 99;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}

.main-total {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.Resizer {
  background: $off-black;
  cursor: row-resize;

  box-sizing: border-box;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.noninteractive {
  pointer-events: none;
}

.Pane2 {
  display: flex;
}

.Resizer.horizontal {
  height: 6px;
  width: 100%;
  margin-top: 2px;
}

.Resizer.horizontal::before {
  color: $black;
  content: '.....';
  padding-left: 50%;
  display: block;
  position: relative;
  top: -7px;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 8pt;
}

.mobile-blocker-page {
  background: #0A2835;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .blocker-image {
    width: 100%;
    height: 50%;
    display: flex;

    img {
      max-width: 60%;
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .blocker-text {
    font-family: Hind;
    font-style: normal;
    font-weight: 600;
    padding: 0 25px;
    color: #12394B;
  }
}

.snackbar-alerts {
  .MuiPaper {
    &-root {
      margin: 10px 0;
    }
  }
}

@media (max-width: 767.98px) {
  .mobile-hidden {
    display: none;
  }
}

@media (min-width: 768px) {
  * {
    &::-webkit-scrollbar {
      width: 15px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $dark-slate;
      border-radius: 10rem;
      border: 5px solid transparent;
      background-clip: content-box;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-corner {
      background: transparent;
      background-color: transparent;
    }
  }
}