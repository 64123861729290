$blue: #339EF3;
$dark-blue: #D8D8D8;
$darker-blue: #334150;
$pale-blue: #73CEF3;
$peacock: #0A2835;
$navy: #0B2029;
$green: #1A8018;
$dark-green: #59870F;
$pale-green: #7DAF7C;
$light-green: #7DB47B;
$flat-green: #356933;
$bright-green: #25A962;
$success: #4CC38E;
$pine: #264E5A;
$off-pine: #142C39;
$dark-pine: #112129;
$light-pine: #316373;
$pale-pine: #4F7775;
$flat-pine: #183746;
$sacramento: #0C171C;
$slate: #3E526B;
$dark-slate: #26303B;
$yellow: #B8953B;
$dark-yellow: #856D2F;
$pale-yellow: #998552;
$light-yellow: #B3A086;
$bright-yellow: #E5FF47;
$read-only: #88705B;
$teal: #35959B;
$orange: #CD7272;
$light-orange: #FF9330;
$bronze: #B6782B;
$orange-banner: #986C2B;
$cantaloupe: #A59683;
$purple: #BF99FE;
$flat-purple: #685DC9;
$red: #CC6464;
$red-error: #CC6464;
$red-banner: #932027;
$error: #9A5B66;
$mostly-black: #081F28;
$camel: #BAA78F;

$black: #131313;
$off-black: #1C1C1E;
$placeholder: #13262F;
$soot: #151D20;
$steel: #656262;
$white: #FFFFFF;
$white-ish: #EBEFF3;
$off-white: #A7A7A7;
$pearl: #BABABA;
$silver: #B1C6C6;
$iron: #7C7C7C;
$pale-gray: #C4C4C4;
$gray: #706f6f;
$light-gray: #E5E5E5;
$dark-gray: #262F39;
$charcoal: #333333;
$body-background-color: #131313;
$text-color: #FFFFFF;
$odd-row-color: #1A1E1F;

$transparent-black: rgba(17, 17, 17, 0.9);

$status-pending-color: $yellow;
$status-running-color: $green;
$status-ended-color: $gray;
$status-failed-color: $red;
$status-cancelled-color: $gray;
$status-stopping-color: $dark-yellow;
$status-liquidated-color: $blue;

.color {
  &--profit {
    color: $pale-green;
  }
}

$form__input--dark-background-color: #16191B;
$form__input--dark-border-color: $dark-gray;

$nav--primary-background-color: #131313;
$nav--primary-border-color: $dark-gray;
$nav--secondary-background-color: $off-black;
$nav--secondary-border-color: $nav--primary-background-color;

$nav__link-color: #EBEFF3;
$nav__link--active-color: $blue;
$nav__link--active-background-color: transparent;
$nav__link-border-color: $off-black;

$table__cell--contract: $pale-yellow;
$table__cell--signal: $light-yellow;
