@import 'src/styles/colors';
@import 'src/styles/typography';

.login-page:not(.modal) {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: $sacramento;
  font-family: Hind;
  font-style: normal;
  font-weight: normal;
  overflow-x: hidden;
}

.login-modal {
  animation: modalLoad 1s cubic-bezier(0, 0.26, 0.12, 0.97);
  align-items: center!important;
  justify-content: center!important;
  display: flex!important;

  @media (min-width: 992px) {
    width: 732px;
    height: 432px;
  }

  @media (max-width: 991.98px) and (min-width: 768px) {
    width: 432px;
    height: 332px;
  }

  @media (max-width: 767.98px) {
    width: calc(100vw - 80px);
  }

  .form__field {
    animation: modalLoad 1s cubic-bezier(0, 0.26, 0.12, 0.97)!important;

    @media (max-width: 767.98px) {
      width: calc(100vw - 146px)!important;

      input {
        width: calc(100vw - 146px)!important;
        min-width: 0!important;
      }
    }
  }

  .button {
    animation: modalLoad 1s cubic-bezier(0, 0.26, 0.12, 0.97)!important;
  }
}

.login-page {
    overflow: hidden;

    &__wrapper {
        @media (min-width: 992px) {
          width: 500px;
          position: relative;
          top: 34%;
          left: 63%;
          transform: translate(-50%, -50%);
          padding: 2.1875rem;
        }

        @media (max-width: 991.98px) and (min-width: 768px) {
          width: 460px;
          position: relative;
          top: 34%;
          left: 69.25%;
          transform: translate(-50%, -50%);
          padding: 2.1875rem;
        }

        @media (max-width: 767.98px) {
          width: 90%;
          height: calc(100% - 175px);
          margin: auto;
        }

        .qr-code {
          width: 128px;
          height: 128px;
          padding: 17px;
          background: $white;
        }

        .spinner {
          width: unset;
          animation: modalLoad 2s cubic-bezier(0, 0.26, 0.12, 0.97)!important;
        }

        .tuned-logo {
           width: 17.5%;
           margin-bottom: 45px;
        }

        &__title {
            color: $silver;
            font-size: 22px;
            line-height: 35px;

            @media (max-width: 767.98px) {
              text-align: center;
              line-height: 25px;
            }
        }

        &__sub {
          animation: titleLoad 2.2s cubic-bezier(0, 0.26, 0.47, 0.98);

          .lock {
            @media (max-width: 767.98px) {
              margin: auto;
              display: table;
            }
          }
        }

        .error {
          font-size: $font-size-sm;
          font-weight: 500;
        }
    }

    .form__field {
        position: relative;
        animation: modalLoad 4s cubic-bezier(0, 0.26, 0.12, 0.97);
        margin-top: 10px;

        &.only-field {
          @media (max-width: 767.98px) {
            margin-top: calc(50vh - 190px) !important;
          }
        }

        &:not(:first-child) {
          margin-top: 25px;
        }

        &__error, &__desc {
            position: absolute;
            margin-top: 3px;

            @media (max-width: 767.98px) {
              line-height: 17px;
            }
        }

        &__error {
          &.unfixed--error {
            position: static;
          }
        }

        &--text {
          &:not(.form__field--checkbox) {
            input {
              -webkit-appearance: none;
              border-radius: 0;
            }
          }
        }

        &--checkbox {

          .unfixed--error {
            position: static;
          }
        }

        &-two--columns {
          display: flex;

          div {
            margin-top: 10px !important;

            &:first-child {
              margin-right: 15px;
              flex: 1 1;
            }

            &:last-child {
              flex: 1 1;
            }
          }
        }

        &-checkbox--label {
          font-family: Hind;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          text-transform: none;
          color: $off-white;
          display: inline-block;
          padding-bottom: 2px;

          a {
            color: $white;

            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        .login-checkbox {
          position: relative !important;
          margin: 40px 0 0 0;
          display: flex;

          & > span {
            padding-top: 0 !important;
            padding-bottom: 0;
            padding-left: 0 !important;

            &:last-child {
              @media (max-width: 464px) {
                margin-top: 5px;
              }
            }
          }

          input {
            height: 0 !important;
            padding: 0;
          }

          .unchecked {
            border: 1px solid $steel;
          }

          .MuiFormControlLabel-label {
            vertical-align: middle;
          }

          &:hover {
            .unchecked {
              border: 1px solid $off-white;
            }
          }
        }

        input {
            position: relative;
            z-index: 1;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $pale-gray;
            color: $pale-gray;
            display: block;
            width: 100%;
            height: 50px;
            transition: background-color .125s, border-color .125s;
            padding: 15px 5px 0;
            font-family: Hind;
            font-style: normal;
            font-weight: normal;
            /* this font doesn't update from the parent */
            font-size: 14px;
            line-height: 22px;

            letter-spacing: 0.05em;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
          -webkit-text-fill-color: $pale-gray;
          -webkit-box-shadow: 0 0 0px 1000px rgba(56, 64, 68, 1) inset;
        }

        input.input-focus {
          outline: none;
        }

        input.password-field {
          -webkit-text-security: disc;
          text-security: disc;
        }

        input.input-focus + label {
          transform: translate3d(5px, -105%, 0) scale(0.85);
          margin-bottom: 0px;
          z-index: 1;
        }

        label {
          position: absolute!important;
          user-select: none;
          font-size: 14px;
          font-weight: 500;
          color: $pale-gray;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          position: absolute;
          bottom: 0;
          backface-visibility: hidden;
          left: 0;
          transform-origin: left top;
          transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
          will-change: transform;
          cursor: text;
          text-transform: uppercase;
        }
    }

    .button {
        height: 50px;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 4px;
        text-align: center;
        letter-spacing: 0.05em;
        display: inline-block;
        font-weight: lighter;
        padding: 5px 5px 1px 5px;
        margin-top: 20px;
        position: relative;
        z-index: 1;
        animation: modalLoad 4s cubic-bezier(0, 0.26, 0.12, 0.97);
        background: $bronze;

        &:focus {
          border: 1px solid $bronze;
        }

        &--far-gap {
          margin-top: 40px;
        }

        &--primary {
          @media (max-width: 767.98px) {
            position: fixed;
            bottom: 20px;
            width: 90%;
          }
        }
    }

    .user-access {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        position: relative;

        & + .user-access {
          margin-top: 10px;
        }
    }

    .access-button {
        font-size: 12px;
        line-height: 19px;
        font-weight: 600;
        color: $off-white;
        animation: modalLoad 4s cubic-bezier(0, 0.26, 0.12, 0.97);

        &:not(.forgot-password) > span {
          color: $cantaloupe;
        }

        img {
          margin-right: 5px;
        }

        &.back-button {
            @media (max-width: 767.98px) {
                position: fixed;
                top: 70px;

                .desktop-back-icon {
                  display: none;
                }

                .mobile-back-icon {
                  transform: rotate(90deg);
                  padding-bottom: 2px;
                }
            }

            @media (min-width: 768px) {
                .mobile-back-icon {
                  display: none;
                }
            }
        }

        &:hover {
          cursor: pointer;

          & > span {
              text-decoration: underline;
          }
        }
    }

    .recaptcha-warning {
        margin-top: 30px;
        width: 70%;
        height: 36px;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: $iron;
    }

    .hidden {
      visibility: hidden;
    }
}

@keyframes modalLoad {
    0% {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }

    33% {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }
    66% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    100% {
      opacity: 1;
    }
}