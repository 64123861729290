@import "colors";

body {
  background: $body-background-color;
  color: $text-color;
  overflow: hidden;
  margin: 0;
}

a {
  text-decoration: none;
}

:focus {
  border: none;
  outline: none;
}

#root {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.reactTooltip {
  opacity: 1 !important;
  background-color: $mostly-black;

  &.white {
    background-color: $white;
    color: $steel;
    font-weight: 600;
    font-size: 12px;

    &.place-top {
      &::after {
        border-top-color: $white !important;
      }
    }
    
    &.place-right {
      &::after {
        border-right-color: $white !important;
      }
    }

    &.place-bottom {
      &::after {
        border-bottom-color: $white !important;
      }
    }

    &.place-left {
      &::after {
        border-left-color: $white !important;
      }
    }

    .pos {
      color: $light-green;
    }

    .neg {
      color: $red-error;
    }
  }

  &.black {
    background-color: $black;
    margin-top: 32px;

    &::after {
      border-top-color: $black !important;
    }
  }

  tr {
    background: unset !important;
  }
}

.hidden {
  display: none!important;
}


.invisible {
  visibility: hidden !important;
}