@import '../../styles/colors';
@import '../../styles/typography';

$header-height: 40px;

.nav {
  &--primary {
    height: $header-height;
    align-items: center;
    background: $nav--primary-background-color;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .nav__link {
      font-size: $font-size-sm;
      padding: 0 1.25rem;
      line-height: $header-height;
    }

    .nav__container {
      height: $header-height;
    }
  }

  &__container {
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;


    &--center {
      justify-content: space-evenly;
    }

    &--right {
      flex: none;

      .asset-explorer-toggler {
        display: none;
      }
    }

    &--portfolio {
      flex: none;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__version {
    color: $dark-blue;
    font-weight: 500;
    font-size: 0.45rem;
    margin-left: 5px;
    background-color: #0A2835;
    border-radius: 10px;
    height: 12px;
    text-align: center;
    vertical-align: middle;
    padding: 2px 4px 2px 4px;
    letter-spacing: 1px;

    &.nav__env {
      background-color: $bronze;
      font-weight: 600;
      padding: 2px 5px 2px 5px;
      user-select: none;
      letter-spacing: unset;
    }
  }

  &__brand {
    margin-left: 20px;

    &--header {
      margin: 0 auto;
      font-weight: 300;
      letter-spacing: .375rem;
      line-height: $header-height;
      width: 64px;
    }

    &--link {
      color: $nav__link-color;
      display: block;
    }

  }

  &__item {
    &--toggler {
      &.support-button {
        &:hover, &.nav__item--focused {
          background-color: transparent;

          .support-img {
            opacity: 1;
          }
        }

        button {
          height: 100%;
        }

        .nav {
          &__link {
            &--parent {
              padding: 0 10px;
              background: transparent;
            }
          }
        }

        .support-img {
          vertical-align: middle;
          opacity: 0.75;
        }
      }

      &.settings-button {
        &.admin-settings {
          .nav__container--dropdown {
            width: 280px;
          }
        }

        div {
          margin-top: 1px;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .nav {
    &__container {
      &--dropdown {
        top: 40px;
      }

      &--portfolio {
        margin-right: auto;
        margin-left: auto;
        width: calc(100vw - 145px);
      }

      &--mobile-portfolio {
        position: relative;
        z-index: 5;
      }

      &--right {
        position: relative;
        z-index: 4;

        .asset-explorer-toggler {
          display: unset;
          margin-right: 10px;

          &:hover:not(.open-asset-explorer) {
            filter: brightness(137.5%);
          }

          &.open-asset-explorer, &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__brand {
      position: relative;
      z-index: 4;
      margin-left: 10px;

      &--header {
        width: unset;
      }
    }

    &__version {
      display: none;
    }

    &__item {
      &--toggler {
        &.support-button {
          margin-right: 5px;

          .nav {
            &__link {
              &--parent {
                padding: 0 5px;
              }
            }
          }
        }

        &.settings-button {
          margin-right: 5px;
          opacity: 0.75;
          background: transparent !important;

          &:hover, &.nav__item--focused {
            opacity: 1;
          }

          & > button {
            height: 40px;

            div {
              width: 1px !important;
              height: 1px !important;
              border: 1px solid $off-white !important;
              padding: 0 !important;
              background: $off-white !important;
              border-radius: 100px;
              margin-left: 10px;
              margin-right: 10px;
              margin-top: 0;
              position: relative;

              &:after, &:before {
                width: 1px;
                height: 1px;
                border: 1px solid $off-white;
                background: $off-white;
                border-radius: 100px;
                content: '';
                position: absolute;
                right: -1px;
              }

              &:after {
                bottom: 5px;
              }

              &:before {
                top: 5px;
              }

              span {
                display: none;
              }
            }
          }

          .arrow {
            display: none;
          }
        }
      }
    }
  }
}