@import '../../styles/colors';
@import '../../styles/typography';

$button-border-width: .125rem;
$button-font-size: $font-size-md;

button {
  background: transparent;
  border: 0;
}

.button {
  background: $dark-yellow;
  border-color: transparent; // ensure button size is identical
  border-style: solid;
  border-width: $button-border-width;
  box-sizing: border-box;
  color: $white;
  display: block;
  font-size: $button-font-size;
  font-weight: 600;
  letter-spacing: .0625rem;
  padding: 1rem;
  text-align: center;
  transition: .2s background ease-out;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: darken($dark-yellow, 5%);
  }

  &--primary {
    background: $pine;

    &:hover {
      background: $cantaloupe;
    }
  }

  &--secondary {
    background: transparent;
    border-color: $white;

    &:hover {
      background: transparentize($white, .90)
    }
  }

  &--errors {
    background: $red;

    &:hover {
      background: $red;
    }
}

  &--checkmark {
    align-items: center;
    background: $blue;
    display: flex;

    &:hover {
      background: darken($blue, 10%);
    }
  }

  &--disabled {
    opacity: 0.5;
    cursor: default;
  }
}
