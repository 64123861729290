@import '../../styles/colors';
@import '../../styles/typography';

.nav__item {
    &.stage {
        clip-path: polygon(calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 10px 100%, 20px 50%, 10px 0%);
        border-right: 0;
        transform: translateX(0);

        &--experiments {
            clip-path: polygon(calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%, 0% 0%);
            background: #21283B !important;

            &:hover {
                background: #2046AD !important;
            }

            .nav__link {
                padding: 5px 20px 1px 12px;

                &--active {
                    background-color: #2046AD !important;
                }
            }
        }

        &--candidates {
            transform: translateX(-18px);
            background: #283C50 !important;

            &:hover {
                background: #3E77AC !important;
            }

            .nav__link {
                &--active {
                    background-color: #3E77AC !important;
                }
            }
        }

        &--release-candidates {
            transform: translateX(-36px);
            background: #1B2629 !important;

            &:hover {
                background: #156166 !important;
            }

            .nav__link {
                &--active {
                    background-color: #156166 !important;
                }
            }
        }

        &--live {
            transform: translateX(-54px);
            background: #2E251A !important;

            &:hover {
                background: #975503 !important;
            }

            .nav__link {
                &--active {
                    background-color: #975503 !important;
                }

                @media (max-width: 409.97px) {
                    padding-right: 10px !important;
                }
            }
        }

        &--syndication {
            margin-left: -72px;
            background: #1C251D !important;

            &:hover {
                background: #356933 !important;
            }

            .nav__link {
                &--active {
                    background-color: #356933 !important;
                }
            }
        }

        &:not(.stage--experiments) {
            .nav__link {
                padding: 5px 20px 1px 30px;
            }
        }

        .new-tests {
            position: absolute;
            right: 8px;
            top: 3px;
            background: #AA3D3D;
            height: 12px;
            width: 12px;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            vertical-align: middle;
            border-radius: 11px;
        }
    }

    .stage {
        &--desktop {
            @media (max-width: 956.98px) {
                display: none;
            }
        }

        &--mobile {
            @media (min-width: 957px) {
                display: none;
            }

            &.experiments {
                @media (max-width: 956.98px) {
                    margin-left: 5px;
                }
            }
        }

        &--non-small-mobile {
            @media (max-width: 409.97px) {
                display: none;
            }
        }
    }
}

.strategy-index {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    position: relative;

    &.open-composer {
        .main-nav {
            &.visible-right-nav:not(.scripts) {
                .nav {
                    &__item {
                        .stage {
                            &--desktop {
                                @media (max-width: 1781.98px) and (min-width: $breakpoint-trades-with-composer) {
                                    display: none;
                                }
                            }

                            &--mobile {
                                @media (min-width: 1782px) {
                                    display: none;
                                }

                                @media (max-width: 1781.97px) and (min-width: $breakpoint-trades-with-composer) {
                                    display: unset;
                                }

                                &.experiments {
                                    @media (max-width: 1781.97px) and (min-width: $breakpoint-trades-with-composer) {
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .nav {
            &__item {
                .stage {
                    &--desktop {
                        @media (max-width: 1293.98px) {
                            display: none;
                        }
                    }

                    &--mobile {
                        @media (min-width: 1294px) {
                            display: none;
                        }

                        @media (max-width: 1293.98px) {
                            display: unset;
                        }

                        &.experiments {
                            @media (max-width: 1293.98px) {
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }

        .scripts {
            .nav__container:not(.right-nav) {
                .nav {
                    &__item {
                        &--create {
                            .public-script-desktop {
                                @media (max-width: 1567.97px) {
                                    display: none;
                                }
                            }
                        }

                        &--icon {
                            @media (max-width: 806.98px) {
                                display: none;
                            }
                        }

                        &--divider {
                            @media (max-width: 806.98px) {
                                border-right: 2px solid $black;
                            }
                        }

                        &.stage {
                            &--live {
                                .nav__link {
                                    @media (max-width: 935.97px) {
                                        padding-right: 10px;
                                    }
                                }
                            }
                        }

                        .stage {
                            &--desktop {
                                @media (max-width: 1547.97px) {
                                    display: none;
                                }
                            }

                            &--mobile {
                                @media (min-width: 1548px) {
                                    display: none;
                                }

                                @media (max-width: 1547.97px) {
                                    display: unset;
                                }

                                &.experiments {
                                    @media (max-width: 1547.97px) {
                                        margin-left: 5px;
                                    }
                                }
                            }

                            &--non-small-mobile {
                                @media (max-width: 935.97px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .main-nav {
        &.scripts {
            .nav {
                &__item {
                    &--create {
                        .public-script-desktop {
                            @media (max-width: 1281.97px) and (min-width: 1236px) {
                                display: none;
                            }

                            @media (max-width: 848.97px) {
                                display: none;
                            }
                        }

                        .public-script-mobile {
                            @media (min-width: 1282px) {
                                display: none;
                            }

                            @media (max-width: 1235.97px) and (min-width: 849px) {
                                display: none;
                            }
                        }
                    }

                    .stage {
                        &--desktop {
                            @media (max-width: 1235.97px) {
                                display: none;
                            }
                        }

                        &--mobile {
                            @media (min-width: 1236) {
                                display: none;
                            }

                            @media (max-width: 1235.97px) {
                                display: unset;
                            }

                            &.experiments {
                                @media (max-width: 1235.97px) {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .main-nav {
        &.visible-right-nav:not(.scripts) {
            .stage {
                &--desktop {
                    @media (max-width: 1469.98px) and (min-width: $breakpoint-trades-stage) {
                        display: none;
                    }
                }

                &--mobile {
                    @media (min-width: 1470px) {
                        display: none;
                    }

                    @media (max-width: 1469.98px) and (min-width: $breakpoint-trades-stage) {
                        display: unset;
                    }

                    &.experiments {
                        @media (max-width: 1469.98px) and (min-width: $breakpoint-trades-stage) {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}