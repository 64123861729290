@import '../../styles/colors';
@import '../../styles/typography';

.nav {
  &--tertiary {
    margin: 0 !important;
    display: flex;
    height: 32px;
    position: static;

    &.main-nav {
      .nav {
        &__container {
          background: $black;
          flex-wrap: nowrap;

          @media (max-width: 377.98px) {
            overflow: hidden;
          }

          .nav {
            &__item {
              background: $nav--secondary-background-color;

              &--divider {
                background: $nav--secondary-background-color;
              }
            }
          }

          &:not(.right-nav) {
            .nav__item {
              &--divider:not(.middle-right) {
                &:before {
                  content: '';
                  width: 20px;
                  height: 30px;
                  margin-left: -19px;
                  background: $nav--secondary-background-color;
                  clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 10px 100%, 20px 50%, 10px 0%);
                }
              }
            }
          }
        }
      }
    }

    // FIXME classes
    .nav__container {
      display: flex;
      background: $nav--secondary-background-color;
      border-bottom: 2px transparent;

      &:not(.right-nav) {
        flex: 1;
        margin: 2px 0 0 0;
      }

      &.right-nav {
        li {
          border-top: 2px solid $black;
          height: 30px;
        }
      }

      &:only-child {
        .full-collapse {
          border-right: 0;
        }
      }
    }

    &.scripts {
      .nav__container:not(.right-nav) {
        flex: 8 1;
      }
    }

    .right-nav {
      border-left: 6px solid $off-black;
      max-width: 550px;

      &.new-script, &.existing-script {
        flex: 3 1;
        border-left: 6px solid $off-black;
        margin-left: -1px;

        &:not(.right-nav-with-composer) {
            @media (min-width: 992px) {
                .action-menu {
                    display: none;
                }
            }

            @media (max-width: 991.98px) {
                .nav {
                    &__item {
                        display: none;
                    }
                }
            }
        }

        .action-menu {
            position: relative;
            border-left: 2px solid $black;
            border-top: 2px solid $black;
            margin-left: 1px;
            height: 30px;

            &:hover {
                cursor: pointer;
            }

            &-toggler {
                float: right;
                width: 1px;
                height: 1px;
                border: 1px solid $off-white;
                background: $off-white;
                border-radius: 100px;
                margin: 13px 10px;
                position: relative;

                &:after, &:before {
                    width: 1px;
                    height: 1px;
                    border: 1px solid $off-white;
                    background: $off-white;
                    border-radius: 100px;
                    content: '';
                    position: absolute;
                    right: -1px;
                }

                &:after {
                    bottom: 5px;
                }

                &:before {
                    top: 5px;
                }
            }

            .action-dropdown {
                position: absolute;
                left: 25px;
                z-index: 1;
                background: $black;
                display: flex;
                flex-direction: column;
                padding: 5px 0 5px 0;
                border: 2px solid $off-black;

                button {
                    width: 100%;
                    text-align: left;
                    padding-top: 2px;
                    padding-bottom: 0;

                    &.active {
                        .action-option {
                            color: $light-gray;
                            font-weight: 500;
                        }
                    }

                    &:hover {
                        background: $iron;

                        .action-option {
                            color: $white;
                        }
                    }
                }

                .action-option {
                    color: $off-white;
                    width: 100%;
                    margin-left: 15px;
                    margin-right: 15px;
                }
            }
        }
      }

      &.right-nav-with-composer {
        &:not(.new-script):not(.existing-script) {
            @media (max-width: $breakpoint-trades-with-composer-off) {
                display: none;
            }
        }

        @media (min-width: 1318px) {
            .action-menu {
                display: none;
            }
        }

        @media (max-width: 1317.98px) {
          .nav {
              &__item {
                  display: none;
              }
          }
        }
      }

      .nav__item {
        &:first-child {
          border-left: 2px solid $black;
        }
      }
    }

    .trades {
      width: 550px;
      flex: none;
    }

    .nav__item {
      border-right: 2px solid $black;
      display: flex;
      overflow: hidden;

      &.middle-left {
        border-right: 0;
        border-left: 2px solid $black;
        height: 30px;
        position: static;

        &.syndication-subscribe {
          background-color: $flat-purple;

          &:hover {
            background-color: $flat-purple !important;
          }

          button {
            color: $light-gray;
          }
        }

        &.mobile-prefilter {
          @media (min-width: 768px) {
            display: none;
          }
        }
      }

      &:hover:not(.mobile-prefilter):not(.stage) {
        background-color: $pine!important;
      }

      &.disabled {
        button {
          cursor: default;
          &:hover {
            background-color: unset !important;
            color: unset !important;
            opacity: 0.5;
          }
        }

        &:hover {
          background-color: unset !important;
          color: unset !important;
          opacity: 0.5;
        }
        opacity: 0.5;
      }

      &--divider {
        margin: auto;
        list-style: none;
        display: flex;
        flex-grow: 1;
        height: 30px;

        .divider-columns {
          margin: auto;
          display: flex;
        }

        .divider-item {
          border-top: 2px solid rgba(39, 39, 39, 0.9);
          border-bottom: 1px solid $black;
          width: 10px;
          height: 0px;
          transform: rotate(90deg);
          margin-right: 5px;
        }

        &.middle-right {
          border-left: 2px solid $black;

          @media (max-width: 767.98px) {
            display: none;
          }
        }
      }

      &--right {
        margin-left: auto;
        list-style: none;
        border-left: 2px solid $black;
        padding-top: 1px;
        background-color: $nav--secondary-background-color;

        &:hover {
          background-color: $pine;
        }

        &:empty {
          border-left: 0;
        }
      }

      &--create, &--dropdown, &--create-as {
        list-style: none;
        background-color: $pine;
        border-left: 2px solid $black;
        height: 30px;

        &:hover {
          background-color: $cantaloupe;
        }

        .create-button {
          color: $light-gray;
          font-size: $font-size-sm;
          font-weight: 400;
          padding: 1px 15px 0;
          height: 100%;
        }

        button {
          &.create-button {
            padding-top: 2px;
          }
        }
      }

      &--dropdown {
        border-left: 2px solid #244250;

        &:hover {
          background-color: $pine;
        }

        & > .create-button {
          padding-left: 10px;
          padding-right: 10px;
        }

        .dropdown-icon {
          &.reverse {
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            margin-bottom: 2px;
          }
        }
      }

      &--create-as {
        position: absolute;
        z-index: 999;
        width: 87px;
        margin-top: 4px;
        margin-left: -70px;
        background-color: $off-black;
        border: 2px solid $black;

        button {
          margin-top: 2px;
        }

        &:hover {
          background-color: $steel;
        }
      }

      &--button {
        padding: 4.5px 15px 2.5px 15px;
        font-family: Hind;
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-xs;
        display: inline-block;
        color: $off-white;

        &:hover {
          color: $light-gray;
          font-weight: 600;

          .create-button {
            filter: grayscale(100%) brightness(232%);
          }
        }

        .create-button {
          vertical-align: middle;
        }
      }

      &--select {
        color: $off-white;
        font-family: Hind;
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-xs;
        line-height: 19px;
        text-align: center;
        padding: 4.5px 15px 2.5px 15px;

        img {
          vertical-align: middle;
        }

        &:hover {
          color: $light-gray;
          font-weight: 600;

          img {
            filter: grayscale(100%) brightness(232%);
          }
        }

        &.back-tests {
          padding-left: 10px;
          padding-right: 5px;
        }

        &.paper-trades {
          padding-left: 5px;
          padding-right: 10px;
        }

        &.all-runs {
          padding-left: 10px;
          padding-right: 10px;

          &.active {
            background: $black;
            color: $light-gray;
          }
        }

        &.active {
          background: $pine;
          color: $light-gray;

          img {
            filter: grayscale(100%) brightness(232%);
          }
        }
      }

      &--icon {
        margin-left: 0;
        list-style: none;
        padding: 0 .5px 0 .5px;
        border-left: 2px solid $black;
        height: 30px;
        background: $nav--secondary-background-color;

        &.left-delete-icon {
          width: 30px;

          .delete-button {
            padding-top: 8px;
            padding-right: 8px;
            padding-left: 8px;
            background-color: #9A5B66;
            height: 30px;
          }
        }

        &.create-pack {
          background: $pine;
          overflow: hidden;
          white-space: nowrap;

          button {
            font-weight: 500;
            color: $light-gray;
            height: 30px;
            padding: 2px 15px 0;
          }
        }

        &.create-syndication {
          background: $flat-green;
          overflow: hidden;
          white-space: nowrap;

          button {
            font-weight: 500;
            color: $light-gray;
            height: 30px;
            padding: 2px 15px 0;
          }
        }

        &:hover:not(.create-syndication):not(.create-pack) {
          background-color: $pine!important;
          cursor: pointer;
        }

        a.icon {
          padding-left: 8px;
          padding-top: 6px;
          display: inline-block;
        }

        .icon {
          position: static;
          padding-right: 8px;
          padding-top: 8px;

          &:hover {
            filter: grayscale(100%) brightness(232%);
          }
        }

        .middle-icon {
          padding-left: 8px;
          padding-top: 4px;
          display: inline-block;

          &:hover {
            filter: grayscale(100%) brightness(232%);
          }
        }

        .download-icon {
          padding-right: 8px;
          padding-top: 4px;
          padding-left: 8px;
          display: block;
        }

        .editor-button {
          padding-top: 8px;
          padding-right: 8px;
          padding-left: 8px;

          &:hover {
            filter: grayscale(100%) brightness(232%);
          }

          &.editor-icon-button {
            img {
              height: 14px;
            }
          }
        }

        .icon, .editor-button {
          height: 30px;
        }
      }

      &--minimize {
        .minimize {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 6px;

          &:hover {
            filter: grayscale(100%) brightness(232%);
          }
        }

        .full-collapse {
          border-right: 2px solid $black;
        }
      }

      &--disabled {
        background-color: rgba(85, 85, 85, 0.5);
        color: $off-white;

        & > * {
          cursor: default;
          color: $off-white !important;
        }

        &.nav__item--dropdown {
          border-left: 2px solid $black;
        }

        &:hover {
          background-color: rgba(85, 85, 85, 0.5);
          cursor: default;

          & > * {
            cursor: default;
          }
        }
      }
    }

    .nav__link {
      background: none !important; // override any hovers
      color: $off-white;
      font-size: $font-size-sm;
      font-weight: 500;
      padding: 5px 12px 1px 12px;
      display: flex;

      &:hover {
        color: $light-gray;
      }

      &--active {
        color: $light-gray;
        font-weight: 600;
        background-color: $black!important;
        padding: 4px 11px 1px 11px;

        &:hover {
          background-color: rgba(55, 81, 93, 0.5)!important;
        }
      }

      .live {
        padding-right: 8px;
        padding-top: 3px;
        height: 0px;
      }

      .editor--img {
        height: 13px;
        padding: 4px 0 0 1px;
      }

      .stage {
        &--live {
          display: inline-flex;
        }

        &:after {
          content: '';

        }
      }
    }

    .nav__dropdown {
      position: absolute;
      z-index: 3;
      top: 32px;
      width: 155px;
      margin-left: -113px;
      margin-top: 3px;
      background: $off-black;
      padding: 5px 0;
      max-height: 100px;
      overflow: auto;

      &:not(.prefilter-dropdown) {
        @media (max-width: 767.98px) {
          margin-left: 0;
          right: 0;
        }
      }

      &.prefilter-dropdown {
        width: 42px;
        margin-left: 0;

        .nav__dropdown--item {
          height: 30px;
          padding: 0;
          display: flex;

          &.back-tests {
            img {
              padding-left: 6px;
            }
          }

          &.paper-trades {
            img {
              padding-right: 3px;
            }
          }

          img, div {
            margin-left: auto;
            margin-right: auto;
          }

          img {
            padding: 8px 0;
          }

          div {
            padding: 5px 0 4px;
          }

          &:hover {
            img {
              filter: grayscale(100%) brightness(232%);
            }
          }
        }
      }

      &--item {
        background: $off-black;
        font-size: $font-size-xs;
        color: $off-white;
        padding: 3px 8px 0;

        &:hover {
          background: $steel;
          color: $light-gray;
          cursor: pointer;
        }

        &.selected {
          color: $light-gray;
          font-weight: 500;
        }
      }
    }
  }
}

// weird width since trades panel takes up too much space
@media only screen and (max-width: $breakpoint-trades) and (min-width: 768px) {
  .nav {
    &--tertiary {
      .right-nav:not(.new-script):not(.existing-script) {
        border-left: 0;
        border-right: 2px solid $black;
        border-bottom: 0;
        display: none;

        .nav__item {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .nav {
    &.main-nav {
      .nav__container:not(.right-nav) {
        border-bottom: 0;
        margin-top: 0;
        border-top: 2px solid $black;
      }

      .nav__item {
        &--minimize {
          display: none;
        }

        &--button {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    &--tertiary {
      background: $nav--secondary-background-color;

      &.main-nav {
        .nav {
          &__item {
            &:first-child {
              display: none;
            }
          }
        }
      }

      .nav__container {
        width: auto;
      }

      .right-nav {
        border-left: 0;
        border-right: 2px solid $black;
        border-bottom: 0;
        display: none;

        .nav__item {
          display: none;
        }
      }

      .nav__item {
        &--right {
          .nav__item--button {
            padding-left: 9px;
            padding-right: 9px;
          }

          .create-button {
            vertical-align: middle;
          }
        }

        &--icon {
          .icon {
            padding-left: 8px;

            &:not(.minimize) {
              display: none;
            }
          }

          &.left-delete-icon, &.secondary {
            display: none;
          }
        }

        &.middle-left:not(.mobile-prefilter) {
          display: none;
        }
      }
    }
  }
}
