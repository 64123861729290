@import '../../styles/colors';
@import '../../styles/typography';

.user-status-banner {
    font-family: Hind;
    font-style: normal;
    background: $peacock;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;

    @media (max-width: 991.97px) {
        span, button {
            font-size: $font-size-wxs !important;
        }
    }

    @media (max-width: 767.97px) {
        display: none;
    }

    span {
        font-weight: 500;
        font-size: $font-size-xs;
        margin-left: auto;
        margin-right: 5px;
        color: $light-gray;
    }

    button {
        font-weight: 600;
        font-size: $font-size-xs;
        margin-right: auto;
        margin-left: 5px;
        color: $white;
        border-radius: 10px;
        background: $bronze;
        padding: 0 12px;

        &.underlined {
            background: transparent;
            margin: 0;
            padding: 0;
            text-decoration: underline;
            font-weight: 500;
            font-size: $font-size-xs;
        }

        @media (max-width: 767.98px) {
          line-height: 17px;
          margin-right: 5px;
        }
    }

    &--error {
        background: $red-banner;

        img {
            margin-left: auto;
        }

        span {
            margin-right: auto;
            margin-left: 5px;
        }
    }

    &--announcement {
        display: flex;
        flex-shrink: 0;
        height: 30px;

        &.maintenance {
            background: $orange-banner;

            img {
                padding-top: 2px;
            }
        }

        &.outage {
            background: $red-banner;
        }

        &.maintenance, &.outage {
            span {
                margin-left: 0;
            }
        }

        span {
            margin-right: auto;
            margin-left: auto;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 2px;
        }

        img {
            padding-left: 10px;
            margin-left: auto;
        }

        a {
            color: $light-gray;
            text-decoration: underline;
        }
    }
}