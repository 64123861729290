@import '../../../../styles/colors';

.track-horizontal {
    position: absolute;
    height: 14px !important;
    transition: opacity 200ms ease 0s;
    opacity: 0;
    right: 2px;
    bottom: 4px;
    left: 2px;
    border-radius: 2px;
}

.track-vertical {
    position: absolute;
    width: 14px !important;
    transition: opacity 200ms ease 0s;
    opacity: 0;
    right: 4px;
    bottom: 2px;
    top: 2px;
    border-radius: 2px;
}

.thumb-horizontal {
    position: relative;
    display: block;
    height: 100%;
    cursor: pointer;
    border-radius: 5px;
    background-color: #26303BB2;
}

.thumb-vertical {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
    background-color: #26303BB2;
}
