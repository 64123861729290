@import '../../../styles/colors';
@import '../../../styles/typography';

.nav {
  &__container--dropdown {
    background: $nav--primary-background-color;
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    min-width: 100%;
    z-index: 9999;
    right: 0px;
    width: 235px;

    .nav__item {
      background: $nav--primary-background-color;
      width: 100%;
    }

    .nav__link {
      &:hover {
        background: $steel;
      }
    }
  }

  &__item {
    &--focused {
      background: $dark-gray;
    }

    &--dropdown {
      cursor: pointer;
      position: relative;

      button {
        background-color: transparent;
        border: 0px;
        width: 100%;
        padding: 0;
      }

      .arrow {
        margin-left: .5rem;
      }

      .nav__link--parent {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
      }
    }

    &--hoverable {
      .nav__container--dropdown {
        display: none;
      }
      &:hover .nav__container--dropdown {
        display: block;
      }
    }
  }

  &__link {
    &--dropdown {
      border-bottom: 1px solid $nav__link-border-color;
      font-size: $font-size-sm;
      text-align: left;

      .support-logo {
        vertical-align: middle;
        margin-right: 10px;
        margin-top: -3px;
      }
    }
  }
}
