@import '../../styles/colors';
@import '../../styles/typography';

.admin-dashboard {
    &__invoices {
        display: flex;
        flex: 1 1;
        flex-direction: column;

        &--panel {
            flex: 7 1;
        }

        &__header {
            padding: 0 10px;

            form {
                display: flex;
                flex-wrap: wrap;
            }

            &--input {
                height: 40px;
                margin-right: 10px !important;
                margin-top: 10px !important;
                margin-bottom: 10px !important;

                &.multi-input {
                    min-width: 200px;

                    .MuiTextField {
                        &-root {
                            min-width: 200px;
                        }
                    }
                }

                &.date-input {
                    .MuiInputBase {
                        &-input {
                            padding-right: 5px !important;
                        
                            &::-webkit-calendar-picker-indicator {
                                margin-top: 10px;
                                margin-left: 0;
                                color: transparent;
                                background: url('../../images/calendar.svg') no-repeat;
                            }
                        }
                    }
                }
                
                .MuiInputBase {
                    &-input {
                        color: $off-white;
                        padding-top: 10.5px;
                        padding-bottom: 10.5px;
                        font-family: "Hind";
                    }

                    &-root {
                        height: 40px;
                    }
                }

                .MuiOutlinedInput {
                    &-notchedOutline {
                        border-color: $light-gray !important;
                    }
                }

                .MuiFormLabel {
                    &-root {
                        color: $light-gray !important;
                        font-family: 'Hind';
                    }
                }
            }

            &--type {
                display: flex;
                margin-right: 10px;
                margin-top: 10px;
                margin-bottom: 10px;

                .tab {
                    color: $light-gray;
                    padding: 9px 10px 6px;
                    font-size: $font-size-sm;
                    font-weight: 500;

                    &:not(.submit-button) {
                        border: 2px solid $white;
                    }
                    

                    &:first-child {
                        border-bottom-left-radius: 5px;
                        border-top-left-radius: 5px;
                        border-right: 0;
                    }

                    &:last-child {
                        border-bottom-right-radius: 5px;
                        border-top-right-radius: 5px;
                    }

                    &:hover:not(.submit-button) {
                        cursor: pointer;
                        background-color: $off-white;
                        color: $off-black;
                    }

                    &.active {
                        background-color: $white;
                        color: $off-black;
                        font-weight: 600;
                    }

                    &.submit-button {
                        border-bottom-right-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            }

            &--submit-button {
                &.clear {
                    margin-left: 10px;
                    
                    &.MuiButtonBase-root {
                        background-color: $bronze !important;
                    }
                }

                &.MuiButtonBase-root {
                    text-transform: none;
                    background-color: $pine !important;
                    color: $light-gray;
                    font-family: "Hind";
                    padding-top: 8px;
                    padding-bottom: 4px;
                    margin-top: 10px;
                    margin-bottom: 10px;

                    & + .MuiButtonBase-root {
                        margin-left: 10px;
                    }
                }
            }

            .submit-button {
                text-transform: none;
                background-color: $pine !important;
                color: $light-gray;
                font-family: "Hind";
                padding-top: 8px;
                padding-bottom: 4px;
            }
        }

        &--table {
            margin-bottom: 50px;

            table {
                white-space: nowrap;
                border-collapse: collapse;

                th, td {
                    padding: 5px 10px;
                    border-left: 1px solid $off-black;
                }

                th {
                    position: sticky;
                    background: $black;
                    top: 0;
                    z-index: 2;
                }

                tbody {
                    .invoice-row {
                        &--dropdown-and-button {
                            display: flex;
                        }

                        &:nth-child(odd) {
                            background: $odd-row-color;
                        }

                        &:hover {
                            cursor: pointer;
                            background: $charcoal;
                        }

                        td {
                            border-left: 1px solid $off-black;
                            text-align: right;
                        }
                    }

                    .fetching {
                        filter: blur(3px);
                    }
                }
            }

            &--footer {
                display: flex;
                flex-wrap: wrap;

                .footer-list-item {
                    margin: 10px;
                }
            }
        }
    }
}