@import '../../styles/colors';
@import '../../styles/typography';

.lock {
  color: $pale-pine;
  margin-top: 5px;

  &__img {
    margin-right: 10px;
  }

  &__site {
    font-family: Hind;
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-xs;
    line-height: 16px;
  }
}