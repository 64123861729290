@import '../../styles/colors';
@import '../../styles/typography';

.settings {
    &__invoice {
        margin: auto;
        width: 650px;

        &--container {
            height: calc(100% - 199px);
            overflow: auto;
        }

        &__details {
            background-color: black;
            padding: 25px 30px;

            & + .settings__invoice__details {
                border-top: 1px solid #393939;
                padding: 27px 30px 23px;
            }

            &.clickable {
                cursor: pointer;

                &:hover {
                    background-color: $soot;
                }
            }

            &__row {
                display: flex;

                &:not(:last-child) {
                    margin-bottom: 15px;
                }

                &.full {
                    div {
                        line-height: 18px;
                    }
                    
                    .settings__invoice__details__row--header {
                        margin-right: 5px;
                    }
                }

                &--left {
                    margin-right: auto;
                    padding-right: 10px;
                }

                &--right {
                    margin-left: auto;
                    text-align: right;
                }

                &--header {
                    color: $off-white;
                    font-size: $font-size-mdlg;
                    font-weight: normal;
                }

                &--body {
                    color: $light-gray;
                    font-size: $font-size-mdlg;
                    font-weight: 600;
                    line-height: 18px;

                    &.clickable {
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &.status {
                        &__paid, &__forgiven {
                            color: $success;
                        }

                        &__outstanding, &__overdue, &__pending_manual_approval, &__rejected, &__cancelled, &__expired {
                            color: $error;
                        }
                    }
                }

                &--footer {
                    color: $silver;
                    font-size: $font-size-sm;
                    font-weight: normal;
                    line-height: 18px;
                }
            }
        }

        &__fees {
            display: flex;
            flex-direction: column;
            margin-top: 25px;

            &__item {
                display: flex;
                padding: 15px 0;

                &:not(.total) {
                    border-bottom: 1px solid black;
                }

                &:hover {
                    .remove-button {
                        visibility: visible;
                    }
                }

                .remove-button {
                    height: 14px;
                    margin: auto 0;

                    @media (min-width: 768px) {
                        visibility: hidden;
                    }
                }

                &--description {
                    margin-right: 10px;
                    font-weight: normal;
                    padding-right: 10px;
                }

                &--amount, &--title {
                    margin-left: auto;
                }

                &--title {
                    margin-right: 10px;
                }

                &--description, &--amount, &--total, &--title {
                    color: $light-gray;
                    font-size: $font-size-mdlg;
                }

                &--amount, &--total {
                    font-weight: bold;
                }
            }

            .submit-button {
                background-color: $bronze !important;
                color: $white;
                text-transform: none;
                box-shadow: none;
                font-weight: 500;
                font-family: "Hind";
                padding-top: 8px;
                padding-bottom: 4px;

                &.warning {
                    background-color: $red-banner !important;
                    margin-left: calc(100% - 162px);
                }

                &.disabled {
                    opacity: 0.5;
                    cursor: unset;
                }
            }

            .settings__general {
                &--buttons {
                    display: flex;
                    flex-wrap: wrap;

                    & > * {
                        margin: 10px;
                    }
                }
            }
        }

        &--input {
            &.MuiTextField {
                &-root {
                    width: 260px;
                    max-width: 100%;
                    margin-right: 10px;

                    .MuiOutlinedInput {
                        &-adornedStart {
                            div {
                                color: $light-gray;
                                margin-right: 5px;
                            }
                        }
                    }
                    
                    .MuiFormLabel {
                        &-root {
                            color: $off-white;
                        }
                    }

                    .MuiInputBase {
                        &-input {
                            padding-top: 20px;
                            padding-bottom: 16px;
                        }
                    }
                }
            }
        }

        &--textarea, &--input {
            &.MuiTextField {
                &-root {
                    margin-top: 5px;
                    margin-bottom: 5px;

                    &:hover {
                        .MuiOutlinedInput {
                            &-notchedOutline {
                                border-color: $off-white;
                            }
                        }
                    }

                    .Mui-focused {
                        .MuiOutlinedInput {
                            &-notchedOutline {
                                border-color: $off-white;
                            }
                        }
                    }
                }
            }

            .MuiInputBase {
                &-input {
                    font-family: 'Hind';
                    color: $light-gray;
                }
            }

            .MuiOutlinedInput {
                &-notchedOutline {
                    border-color: $off-white;
                }
            }
        }

        &__refundable {
            width: 100%;
            background-color: black;
            padding: 20px 10px;
            
            .admin-dashboard__invoices__header--input {
                &.multi-input {
                    width: 380px;
                    max-width: calc(100% - 20px);

                    .MuiTextField {
                        &-root {
                            width: 380px;
                            max-width: calc(100% - 20px);
                        }
                    }
                }
            }

            .settings__invoice--input {
                &.MuiTextField {
                    &-root {
                        max-width: calc(100% - 20px);
                    }
                }
            }
        }

        &__date-input {
            width: 100%;

            .settings__invoice--input {
                width: 350px;
                max-width: calc(100% - 20px);
            }

            .MuiButtonBase {
                &-root {
                    width: 190px;
                }
            }

            .MuiInputBase {
                &-input {
                    padding-right: 5px !important;
                
                    &::-webkit-calendar-picker-indicator {
                        margin-top: 10px;
                        margin-left: 0;
                        color: transparent;
                        background: url('../../images/calendar.svg') no-repeat;
                    }
                }
            }
        }

        &--buttons {
            .MuiButton-root {
                .submit-button {
                    &.disabled {
                        opacity: 0.5;
                        cursor: unset;
                    }
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .settings {
        &__invoice {
            width: 100%;
        }
    }
}

@media (max-width: 767.98px) {
    .settings {
        &__invoice {
            &--container {
                height: calc(100% - 144px);
            }

            &__details {
                &__row {
                    flex-direction: column;

                    &--left {
                        padding-right: 0;
                    }

                    &--right {
                        margin-left: 0;
                        margin-right: auto;
                        margin-top: 15px;
                        text-align: left;
                    }
                }
            }

            &__fees {
                &--button {
                    position: absolute;
                    top: 76px;
                    right: 25px;
                    padding: 2px 15px 0;
                }
            }
        }
    }
}