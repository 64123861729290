.form__field--labeled {
  flex-wrap: wrap;

  .form__field {

    height: 40px;
    line-height: 40px;

    &--boolean {
      // input:not(:checked) + label:before,
      // input:checked + label:before {
      //   top: 6px;
      // }

      // input:checked + label:after {
      //   top: 13px;
      // }

      input:not(:checked) + label:before,
      input:checked + label:before,
      input:checked + label:after {
        transform: translateY(6px);
      }
    }

    &--text {
      flex: 1;
      margin: 0;
      min-width: 4rem;
    }

    &--select {
      flex: 1;
      margin: 0;
      min-width: 10rem;
    }

    &__label {
      padding-right: .5rem;
    }
  }

  .form__field__wrapper {
    label {
      margin-bottom: 0;
    }
    .form__field__label {
      user-select: none;
      padding-bottom: 0;
      height: 40px;
      line-height: 40px;
    }
  }

  .form__field__wrapper:not(.form__field__wrapper--toggleable) {
    .form__field--boolean {
      flex: 1;
      margin: 0;
    }
  }

  .form__field__wrapper--toggleable {

    .form__field--boolean:nth-of-type(2) {
      flex: 1;
      margin: 0;
    }

  }

}
