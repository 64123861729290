@import '../../styles/colors';

.splash-page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    text-align: center;

    &__wrapper {
        width: 25%;
        position: relative;
        top: 40%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
    }
}
